import React from "react";
import { graphql } from 'gatsby'
import loadable from "@loadable/component"
import LayoutTwo from "../components/layoutTwo";
import sitelogoimage from "../images/logo.png";
import { useTranslation } from 'react-i18next';
import { useLocalization } from "gatsby-theme-i18n"
// import propertyDetailsimg2 from "../images/property_img_1.png";
import Seo from '../components/seo';

import PropertyBanner from "../components/PropertyBanner/PropertyBanner";
import PropertyNavSticky from "../components/PropertyNavSticky/PropertyNavSticky";
import PropertyDescription from "../components/PropertyDescription/PropertyDescription";
import Enquire from "../components/Enquire/Enquire";
const FeaturedProperties = loadable(() => import("../components/FeaturedProperties/SimilarProperties"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModuleGlobal"));

const PropertyDetail = ({ data, children, location }) => {
    const { t } = useTranslation();
    const { locale } = useLocalization()

    //seo title, h1 and desc prepare, this can send to utilis function later
    let desc_meta_ptype = data?.strapiProperty?.building?.strapi_json_value?.length > 0 ? data?.strapiProperty?.building_locale[locale].join(" "+t("and")+" ") : t("property")

    let cap_ptype = desc_meta_ptype?.charAt(0).toUpperCase() + desc_meta_ptype?.slice(1);

    let desc_meta_seachtype = t("for_sale")
    if (data.strapiProperty?.search_type == "lettings") {
        desc_meta_seachtype = t("to_rent")
    }
    let desc_meta_beds = data.strapiProperty?.bedroom
    let desc_meta_address = data.strapiProperty?.display_address
    let desc_meta_price = `£` + new Intl.NumberFormat('en-UK').format(data.strapiProperty?.price)

    let pagetitle = `${cap_ptype} ${desc_meta_seachtype} ${t("with")} ${desc_meta_beds} ${t("bedrooms")} ${t("in")} ${desc_meta_address} ${t("at")} ${desc_meta_price}`

    let pagemetadesc = `${t("know_details")} ${desc_meta_ptype} ${desc_meta_seachtype} ${t("with")} ${desc_meta_beds} ${t("bedrooms")} ${t("in")} ${desc_meta_address} ${t("at")} ${desc_meta_price}. ${t("book_a_viewing")} ${process.env.GATSBY_SITE_NAME} ${t("get_assistance", {desc_meta_ptype: desc_meta_ptype})}`

    let pageUrl = process.env.GATSBY_SITE_URL + (location?.pathname).replace(/\/?$/, '/')
    let myimgtransforms = ''
    if (data.strapiProperty?.imagetransforms?.images_Transforms) {
        myimgtransforms = JSON.parse(data.strapiProperty?.imagetransforms?.images_Transforms);
    }
    let pageImg = sitelogoimage;
    if (myimgtransforms && Object.keys(myimgtransforms).length > 0) {
        let mymetaimg = Object.values(myimgtransforms);
        let pageImg_filt = []
        for (const myimgtransform in myimgtransforms) {
            if (typeof myimgtransforms[myimgtransform]['webp'] !== "undefined") {
                pageImg_filt = Object.values(myimgtransforms[myimgtransform]['webp']);
                break;
            }
        }
        if (pageImg_filt.length > 0)
            pageImg = pageImg_filt[0]
    }


    var ldJson = {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": data.strapiProperty?.slug.replace(/-/g, " "),
        "image": data.strapiProperty?.images?.strapi_json_value[0]?.url,
        "description": pagemetadesc,
        "brand": {
            "@type": "Organization",
            "name": process.env.GATSBY_SITE_NAME,
            "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`
        },
        "offers": {
            "@type": "Offer",
            "url": pageUrl,
            "priceCurrency": "GBP",
            "price": data.strapiProperty?.price,
            "availability": "https://schema.org/InStock"
        }
    
    };
    var imagename = "property.images.details";

    let processedImages = JSON.stringify({});
    if (data?.strapiProperty.imagetransforms?.images_Transforms) {
        processedImages = data?.strapiProperty?.imagetransforms?.images_Transforms;
    }

    let propertyDetailImg = [];
    //if((data?.strapiProperty?.images).length > 0) {
    for (let i = 0; i < data?.strapiProperty?.images?.strapi_json_value.length; i++) {
        if (data.strapiProperty?.images?.strapi_json_value[i].url) {
            propertyDetailImg.push(data.strapiProperty?.images?.strapi_json_value[i].url);
        }
    }

    //}
    // we could server resized version instead of original one to avoid load time
    //features = []
    //if(data.strapiProperty?.accommodation_summary?.strapi_json_value)
    let features = []//data.strapiProperty?.accommodation_summary?.strapi_json_value;

    const url = typeof window !== 'undefined' ? window.location.href : ''

    return (
        <>
            <Seo title={pagetitle} description={pagemetadesc}>
                <meta name="image" content={pageImg} />
                <meta name="og:image" content={pageImg} />
                <meta name="og:image:width" content="400" />
                <meta name="og:image:height" content="300" />
                <meta name="twitter:image" content={pageImg} />
                <meta name="og:url" content={pageUrl} />
                <meta name="twitter:url" content={pageUrl} />
                <link rel="canonical" href={pageUrl} />
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
                />
            </Seo>
            <LayoutTwo footerContact={"footercontact"} tag="property-details-template">
                <div className="layout-padding-top">
                    <PropertyBanner {...data.strapiProperty} propImg={propertyDetailImg} processedImages={processedImages} strapi_id={data.strapiProperty.strapi_id} imagename={imagename} crm_id={data?.strapiProperty?.crm_id} prop_id={data?.strapiProperty?.strapi_id} />

                    <PropertyNavSticky  {...data.strapiProperty} propImg={propertyDetailImg} processedImages={processedImages} imagename={imagename}
                        tag="property-details-template"
                    />

                    <PropertyDescription propImg={propertyDetailImg} processedImages={processedImages} imagename={imagename} features={features} {...data.strapiProperty} />

                    <FeaturedProperties prop_id={data?.strapiProperty?.strapi_id}
                        tag="property-details"
                    />

                    <ValuationModule />
                </div>
                <div className="d-md-none">
                    <Enquire tag="property-details" strapi_id={data.strapiProperty.strapi_id} crm_id={data.strapiProperty.crm_id} display_address={data.strapiProperty.display_address} pageurl={url} propImg={propertyDetailImg} status={data?.strapiProperty?.status}/>
                </div>
            </LayoutTwo>
        </>

    )
}

export const query = graphql`
  query ($crmid: String!, $language: String!) {
    strapiProperty(crm_id: {eq: $crmid}, locale: {eq: $language}) {
        title
        title_locale {
            ar
            en
            fr
            ru
        }
        floorarea_type_locale {
            ar
            en
            fr
            ru
        }
        tenure_locale {
            ar
            en
            fr
            ru
        }
        building_locale {
            ar
            en
            fr
            ru
        }
        crm_id
        strapi_id
        display_address
        display_address_locale {
            ar
            en
            fr
            ru
        }
        latitude
        id
        floorarea_min
        status
        floorarea_type
        slug
        longitude
        long_description{
            data {
                long_description
            }
        }
        price
        description {
            data {
              description
            }
        }
        bedroom
        bathroom
        selling_info {
            tenure {
              type
            }
        }
        imagetransforms {
            images_Transforms
        }
        price_qualifier
        epc {
            strapi_json_value {
              srcUrl
            }
        }
        office_mapping
        negotiator_mapping
        strapi_id
        search_type
        images {
            strapi_json_value {
              url
              srcUrl
            }
        }
        floorplan {
            strapi_json_value {
              srcUrl
            }
        }
        building {
            strapi_json_value
        }
   }
  }
`

export default PropertyDetail