import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import { Container, Row, Col } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import PropertyKeyDetails from "./PropertyKeyDetails";
import PropertyCalculator from "../PropertyCalculator/PropertyCalculator";
import PropertyDetailsMap from "../PropertyDetailsMap/PropertyDetailsMap";
import PropertySidebar from "../PropertySidebar/PropertySidebar";
import { useAllStrapiOffice } from "../../hooks/use-all-strapioffice"
import './assets/styles/_index.scss';
import ReadMore from "../ReadMore/ReadMore";

const PropertyDescription = (props) => {
    const { t } = useTranslation()
    const { locale } = useLocalization()
    const data_desc = useStaticQuery(graphql`
        query {
            allStrapiProperty(filter: {publish: {eq: true}}, limit: 500) {
                edges {
                  node {
                    crm_id
                    locale
                    description {
                      data {
                        description
                      }
                    }
                    long_description {
                      data {
                        long_description
                      }
                    }
                  }
                }
            }
        }
    `);
    let prop_desc;
    data_desc.allStrapiProperty.edges.map((n, index) => {
        if(n.node.locale == locale && props.crm_id == n.node.crm_id){
            prop_desc = n.node.long_description?.data?.long_description
            if(prop_desc == ""){
                prop_desc = n.node.description?.data?.description
            }
        }
    });

    // Sticky scroll
    const [scroll, setScroll] = useState(false)
    const [propDesc, setPropDesc] = useState(null)
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 750)
        })
        window.addEventListener("mousemove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
                setRenderComponent(true)
            }
        })
        if(prop_desc != null && propDesc == null){
            setPropDesc(prop_desc);
        }
    }, [prop_desc])
    // Sticky scroll
    // let prop_desc = locale_data?.long_description?.data?.long_description
    // if (prop_desc == "") {
    //     prop_desc = locale_data?.description?.data?.description
    // }

    var office_data = useAllStrapiOffice()
    office_data = office_data.allStrapiOffice.nodes
    let office_key;
    if (props?.office_mapping != "") {
        for (let k in office_data) {
            if (props?.office_mapping == office_data[k].property_office_mapping) {
                office_key = k;
                break;
            }
        }
    }
    let my_display_address = props?.display_address

    if(locale != "en"){
        my_display_address = props?.display_address_locale[locale]
    }
    return (
        <section className="property-desc-wrapper" id="property-desc-wrapper">
            <Container>
                <Row>
                    <Col xl={7}>
                        <PropertyKeyDetails {...props} />

                        <Row>
                            <Col>
                                <div className="property-split-line"></div>
                            </Col>
                        </Row>

                        {prop_desc && <Row>
                            <Col lg={12}>
                                <div className="property-desc-title">{t("prop_desc")}</div>
                                <div className="property-desc-text">{propDesc}</div>
                            </Col>
                        </Row>}

                        {prop_desc && <Row>
                            <Col>
                                <div className="property-split-line"></div>
                            </Col>
                        </Row>}

                        {props?.features && props?.features.length > 0 && <Row>
                            <Col lg={12}>
                                <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={100} offset={10}>
                                    <div className="property-desc-title">{t("features")}</div>
                                    <ul className="list-unstyled property-features-list">
                                        {(props?.features).map((feature, i) => <li>{feature}</li>)}
                                    </ul>
                                </ScrollAnimation>
                            </Col>
                        </Row>}

                        {props?.features && props?.features.length > 0 && <Row>
                            <Col>
                                <div className="property-split-line"></div>
                            </Col>
                        </Row>}

                        {props?.search_type === "sales" && 
                            <ScrollAnimation animateIn="animate__fadeIn" animateOnce delay={100} offset={10}>
                                <PropertyCalculator prop_price={props?.price} />
                            </ScrollAnimation>
                        }

                        {props?.search_type === "sales" && 
                            <Row>
                                <Col>
                                    <div className="property-split-line"></div>
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col lg={12}>
                                <div className="property-desc-title">{my_display_address}</div>
                                {renderComponent &&
                                    <PropertyDetailsMap
                                        lat={props?.latitude}
                                        lng={props?.longitude}
                                    />
                                }
                                {/* <div className="desc-link-text"><span>Want to explore Keighley further?</span> Explore our <Link to="" className="link-underline">Keighley Area Guide</Link></div> */}
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={1}></Col>
                    <Col xl={4}>
                        <div className={`property-sidebar position-sticky ${scroll ? "scrolled" : ""}`}>
                            {office_key >= 0
                                ? <PropertySidebar propofficedata={office_data[office_key]} />
                                : <PropertySidebar />
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PropertyDescription