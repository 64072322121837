import React from "react";
import { useStaticQuery, graphql } from "gatsby"
// import ContentModule from "../../modules/content-render";
// import CTALink from "../../modules/cta_link"
import './assets/styles/_index.scss';
import { useLocalization } from "gatsby-theme-i18n"
import { CTALink } from "../../utils/common/CTALinkModule_locale"
const {ContentModule } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
//const {CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")

const PropertyValuationCard = (props) => {
  const { locale } = useLocalization()
    const data = useStaticQuery(graphql`
      query {
        allStrapiSiteConfig {
          edges {
            node {
              locale
              news_details_sidebar_content {
                data {
                  news_details_sidebar_content
                }
              }
              sidebar_cta_title
              sidebar_cta_link {
                slug_new
                target_window
                external_link
                link_type
                strapi_parent {
                  slug_new
                }
              }
            }
          }
        }
      }
    `);

    var sidebarData;
    data.allStrapiSiteConfig.edges.map((n, index) => {
        if(n.node.locale == locale){
          sidebarData = n.node
        }
    });
    return (
        <React.Fragment>
            {sidebarData && <div className="property-sidebar-valuation-wrapper">
                <ContentModule Content={sidebarData.news_details_sidebar_content?.data?.news_details_sidebar_content} />
                <div className="property-sidebar-valuation-btn">
                    {sidebarData.sidebar_cta_link && <CTALink class="button button-secondary-outline" link={sidebarData.sidebar_cta_link} title={sidebarData.sidebar_cta_title} target_window={sidebarData.sidebar_cta_link?.target_window} />}
                </div>
            </div>}
        </React.Fragment>
    )
}

export default PropertyValuationCard